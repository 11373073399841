(async () => {
  function wireButtons() {
    document
      .querySelector("#form-submit")
      .addEventListener("click", async (e) => {
        disableForm();
        const form = document.querySelector("form");
        const fd = new FormData(form);
        const res = await fetch("/api/memories", {
          body: JSON.stringify(Object.fromEntries(fd)),
          method: "POST",
        });
        if (res.status !== 202) {
          console.error(`POST /memories: error response ${res.status}`);
          renderSubmitError();
          return;
        }
        renderSubmitSuccess();
        drawMemories();
      });

    document
      .querySelector("#form-delete")
      .addEventListener("click", async (e) => {
        disableForm();
        try {
          const res = await fetch("/api/my-memory", {
            method: "DELETE",
          });
          if (res.status !== 204) {
            console.error(
              `DELETE /api/my-memory: error response ${res.status}`
            );
          }
          fillForm();
          document.querySelector("#form-message").value = "";
          document.querySelector("#form-delete").classList.add("hidden");
          drawMemories();
        } catch (e) {
          console.error(`DELETE /api/my-memory`, e);
          return;
        } finally {
          enableForm();
        }
      });

    if (window.innerWidth > 766) {
      const dialog = document.querySelector("dialog");
      dialog.addEventListener("click", (e) => {
        console.log(e, dialog.getBoundingClientRect());
        const outside = (x, y) => {
          const { top, right, bottom, left } = dialog.getBoundingClientRect();
          return y < top || y > bottom || x < left || x > right;
        };
        if (outside(e.clientX, e.clientY)) {
          dialog.close();
        }
      });
      dialog.querySelector('span').addEventListener('click', (e) => {
        dialog.close();
      })

      document.querySelectorAll("img").forEach((img) => {
        if (img.classList.contains('no-zoom') ) return;
        img.addEventListener("click", (e) => {
          dialog.querySelector("img").src = img.src;
          dialog.querySelector('p').textContent = img.alt;
          dialog.showModal();
        });
      });
    }
  }

  function disableForm() {
    document
      .querySelectorAll("form button")
      .forEach((b) => (b.disabled = true));
  }

  function enableForm() {
    document
      .querySelectorAll("form button")
      .forEach((b) => (b.disabled = false));
  }

  function renderSubmitSuccess() {
    document.querySelector("form").classList.add("hidden");
    document.querySelector("#memory-submit-success").classList.remove("hidden");
  }

  function renderSubmitError() {
    enableForm();
    document.querySelector("#memory-submit-error").classList.remove("hidden");
  }

  async function drawMemories() {
    await buildList();
    await fillForm();
  }

  async function fillForm() {
    const form = document.querySelector("form");
    const message = form.querySelector("#form-message");
    message.parentNode.dataset.replicatedValue = message.value;
    try {
      const mr = await fetch("/api/my-memory");
      if (mr.status === 404) {
        return;
      }
      const memory = await mr.json();
      const form = document.querySelector("form");
      document.querySelector("#memory-copy-new").classList.add("hidden");
      document.querySelector("#memory-copy-edit").classList.remove("hidden");
      form.querySelector("#form-name").value = memory.name;
      form.querySelector("#form-delete").classList.remove("hidden");
      message.value = memory.message;
      message.parentNode.dataset.replicatedValue = memory.message;
    } catch (e) {
      console.error("fill form from my-memory", e);
    }
  }

  async function buildList() {
    const list = document.querySelector("#memories-list");
    list.innerHTML = "";

    try {
      const mr = await fetch("/api/memories");
      if (!mr.ok || mr.headers.get("content-length") === "0") {
        console.error(`memories: ${mr.status}`);
        return;
      }
      const memories = await mr.json();
      if (!memories || memories.length <= 0) {
        return;
      }
      const template = document.querySelector("#template-memory");
      for (let memory of memories) {
        const clone = template.content.cloneNode(true);
        clone.querySelector("p:first-of-type").textContent = memory.message;
        clone.querySelector("span").textContent = memory.name;
        list.appendChild(clone);
      }
    } catch (e) {
      console.error(`failed to get memories: ${e}`);
      document
        .querySelector("#memories-error-message")
        .classList.remove("hidden");

      if (process.env.NODE_ENV !== "production") {
        const memories = [
          { name: "testname", message: "lorem ipsum" },
          {
            name: "testname",
            message:
              "Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima doloribus magni tempora veritatis mollitia veniam voluptates temporibus molestias corrupti, maxime iusto commodi nam in voluptatum tempore adipisci aspernatur? Sed, unde.",
          },
          {
            name: "testname",
            message:
              "Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima doloribus magni tempora veritatis mollitia veniam voluptates temporibus molestias corrupti, maxime iusto commodi nam in voluptatum tempore adipisci aspernatur? Sed, unde.",
          },
        ];
        const template = document.querySelector("#template-memory");
        for (let memory of memories) {
          const clone = template.content.cloneNode(true);
          clone.querySelector("p:first-of-type").textContent = memory.message;
          clone.querySelector("span").textContent = memory.name;
          list.appendChild(clone);
        }
      }
    }
  }

  function main() {
    if (!"content" in document.createElement("template")) {
      document
        .querySelector("#memories-unsupported-message")
        .classList.remove("hidden");
    }
    wireButtons();
    drawMemories();
    fillForm();
  }

  main();
})();
